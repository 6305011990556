.section-job {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-areas: 
    "section-heading section-heading section-heading section-heading section-heading section-heading section-content section-content section-content section-content section-content section-content"; 
  @extend .col-gap;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas:
      "section-heading section-heading section-heading section-heading section-heading section-heading"
      "section-content section-content section-content section-content section-content section-content"; 
  }

  .section-heading {
    grid-area: section-heading;
  }

  .section-content {
    grid-area: section-content;

    & > * {
      margin-bottom: 1em;
      max-width: 560px;
      @extend .text-md;
      @extend .line-height-lg;
    }

    h2 {
      @extend .text-lg;
    }

    * + h2 {
      margin: 2em 0 1em;
    }

    ul {
      @extend .list-unordered;
    }

    a {
      text-decoration: underline;
    }
  }
}