.section-careers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-areas: 
    "section-heading section-heading section-heading section-heading section-heading section-heading section-content section-content section-content section-content section-content section-content"; 
  @extend .col-gap;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas:
      "section-heading section-heading section-heading section-heading section-heading section-heading"
      "section-content section-content section-content section-content section-content section-content"; 
  }

  .section-heading {
    grid-area: section-heading;
  }

  .section-content {
    grid-area: section-content;

    .text-md {
      max-width: 560px;
    } 
  }

  .list-careers {
    width: 100%;

    .section-link {
      display: flex;
      justify-content: space-between;
      @extend .pb-xs;

      .location {
        text-align: right;
        @include media-query('small') {
          display: none;
        }
      }
    }
  }
}