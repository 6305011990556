.cookie-consent {
//   background: var(--aswad-black);
  background: rgb(56, 56, 56);
  color: var(--limestone);
  display: flex;
  align-items: center;
  height: 10rem;
  position: fixed;
//   right: 12rem;
  bottom: 0;
  width: 50%;

  @include media-query('large') {
    right: 3rem;
  }
  @include media-query('medium') {
    right: 2rem;
  }

  p {
    font-size: 1.3rem;
    padding: .25rem 2rem 0;

    @include media-query('medium') {
      font-size: 1.2rem;
    }

    a {
      color: var(--limestone);
      text-decoration: underline;

      &:hover {
        color: #FFF;
        text-decoration: none;
      }
    }
  }

  .close {
    // color: var(--limestone);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    height: 4rem;
    width: 30rem;
    background-color: lightgray;
    border-radius: 5px;

    i {
      @include transition;
    }

    &:hover {
      i {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
      }
    }
  }

  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
}
