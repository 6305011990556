.masthead {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content 1fr max-content; 
  grid-template-areas: 
    "masthead-btn masthead-btn masthead-btn masthead-btn masthead-btn masthead-btn masthead-heading masthead-heading masthead-heading masthead-heading masthead-heading masthead-heading"
    "masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image"; 
  @extend .col-gap;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas: 
      "masthead-heading masthead-heading masthead-heading masthead-heading masthead-heading masthead-heading"
      "masthead-image masthead-image masthead-image masthead-image masthead-image masthead-image"; 
  }

  .masthead-btn {
    display: grid;
    align-content: end;
    grid-area: masthead-btn;

    @include media-query('small') {
      display: none;
    }
  }

  .masthead-heading {
    grid-area: masthead-heading;
  }

  .masthead-image { 
    grid-area: masthead-image;
    line-height: 0;
    @extend .mt-lg;



    img {
      @extend .img-fluid;
    }
  }
}
