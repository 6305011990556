.btn {
  align-items: center;
  background-color: transparent;
  border: var(--border);
  display: inline-flex;
  font-weight: 400;
  height: 5rem;
  justify-content: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  width: 100%;
  @include transition;

  &:hover {
    border: var(--border-hover);
    color: var(--black);
  }
}