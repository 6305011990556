.section-team {
  border-top: var(--border);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content min-content; 
  grid-template-areas:
    "section-no section-no section-no section-no section-no section-no section-heading section-heading section-heading section-heading section-heading section-heading"
    "section-item-1 section-item-1 section-item-1 section-item-2 section-item-2 section-item-2 section-item-3 section-item-3 section-item-3 section-item-4 section-item-4 section-item-4"
    ". . . . . . section-additional section-additional section-additional section-additional section-additional section-additional";
  @extend .col-gap;
  @extend .pt-sm;

  @include media-query('medium') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content min-content;
    grid-template-areas:
    "section-no section-no section-no section-no section-no section-no section-heading section-heading section-heading section-heading section-heading section-heading"
    "section-item-1 section-item-1 section-item-1 section-item-2 section-item-2 section-item-2 section-item-3 section-item-3 section-item-3 section-item-4 section-item-4 section-item-4"
    "section-additional section-additional section-additional section-additional section-additional section-additional section-additional section-additional section-additional section-additional section-additional section-additional";
  }

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content min-content;
    grid-template-areas: 
      "section-heading section-heading section-heading section-heading section-heading section-heading"
      "section-item-1 section-item-1 section-item-1 section-item-2 section-item-2 section-item-2"
      "section-item-3 section-item-3 section-item-3 section-item-4 section-item-4 section-item-4"
      "section-additional section-additional section-additional section-additional section-additional section-additional";
    row-gap: 4em;   
  }

  .section-no { 
    grid-area: section-no; 
    @extend .hide-sm;
  }

  .section-heading { 
    grid-area: section-heading;
    @extend .pb-md;
  }

  .section-item {
    h3 {
      @extend .pt-2xs;
      @extend .pb-xs;
    }

    p, ul {
      max-width: 96%;
    }
  }

  .section-item-1 {
    grid-area: section-item-1;
    @extend .section-item;
  }

  .section-item-2 {
    grid-area: section-item-2;
    @extend .section-item;
  }

  .section-item-3 {
    grid-area: section-item-3;
    @extend .section-item;
  }

  .section-item-4 {
    grid-area: section-item-4;
    @extend .section-item;
  }

  .section-additional { 
    grid-area: section-additional;

    h3 {
      display: block;
      @extend .pb-sm
    }

    .list-additional {
      display: grid; 
      align-items: start;
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: min-content min-content min-content; 
      grid-template-areas: 
        "list-item list-item list-item"
        "list-item list-item .";
      margin: 0;
      padding: 0;
      @extend .col-gap;

      @include media-query('small') {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: min-content min-content min-content min-content; 
        grid-template-areas: 
          "list-item list-item"
          "list-item list-item"
          "list-item .";
      }

      .list-item {
        border-top: var(--border);
        @extend .pt-2xs;
        @extend .pb-xs;
      }
    }
  }

  img {
    line-height: 0;
    @extend .img-fluid; 
  }
}