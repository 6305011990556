.section-regular {
  border-top: var(--border);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content min-content; 
  grid-template-areas: 
    "section-no section-no . . . . section-text section-text section-text section-text section-text ."
    ". . . . . . section-link section-link section-link . . .";
  @extend .b-flush-left;
  @extend .col-gap;
  @extend .pt-sm;

  @include media-query('medium') {
    grid-template-areas: 
      "section-no section-no . . . . section-text section-text section-text section-text section-text section-text"
      ". . . . . . section-link section-link section-link section-link section-link section-link"; 
  }

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 
      "section-text section-text section-text section-text section-text ."
      "section-link section-link section-link section-link section-link ."; 
  }

  .section-no { 
    grid-area: section-no; 
    @extend .hide-sm;
  }

  .section-text {
    grid-area: section-text;
  }

  .section-link {
    grid-area: section-link;
  }
}