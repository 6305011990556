.hero-split {
  display: grid; 
  grid-template-columns: 6fr 6fr; 
  grid-template-rows: min-content; 
  grid-template-areas: 
    "hero-text hero-image"; 
  width: 100%; 
  @extend .col-gap;
  @extend .outer-gap;
  @extend .pb-xl;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content; 
    grid-template-areas: 
      "hero-text hero-text hero-text"
      ". hero-image hero-image"; 
  };

  .hero-text {
    grid-area: hero-text;
    @extend .pb-md;
  }

  .hero-image {
    grid-area: hero-image;
    line-height: 0;

    img {
      @extend .img-fluid;
    }
  }
}