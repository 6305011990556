.btn-pill-v {
  border-radius: 2rem;
  height: 6rem;
  width: 4rem;
  @extend .btn;
  
  i {
    @include transition;
  }

  &:hover {
    i {
      margin-bottom: -1.5rem;
    }
  }
}

.site-footer {
  .btn-pill-v {
    display: block;
    i {
      margin-top: 2rem;
    }

    &:hover {
      i {
        margin-top: .5rem;
      }
    }
  }
}