.section-merch {
  border-top: var(--border);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-areas: 
    "section-heading section-heading section-heading section-heading section-heading section-heading section-content section-content section-content section-content section-content section-content"; 
  @extend .col-gap;
  @extend .pt-sm;
  @extend .b-flush-left;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas: 
      "section-heading section-heading section-heading section-heading section-heading section-heading"
      "section-content section-content section-content section-content section-content section-content"; 
  }

  .section-heading {
    grid-area: section-heading;
  }

  .section-content {
    grid-area: section-content;
    
    .links {
      display: grid; 
      align-items: start;
      grid-template-columns: 1fr 1fr; 
      grid-template-areas: 
        "section-link ."; 
      @extend .col-gap;

      @include media-query('small') {
        grid-template-areas: 
          "section-link section-link";  
      }

      .section-link {
        grid-area: section-link;
      }
    }

    p {
      max-width: 560px;
    }
  }
}