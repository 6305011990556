html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  background: #FFF;
  height: 100%;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  color: var(--aswad-black);
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

::selection {
  background-color: var(--aswad-black);
  color: #FFF;
}

::-moz-selection  {
  background-color: var(--aswad-black);
  color: #FFF;
}

img { 
	image-rendering: -webkit-optimize-contrast;
}