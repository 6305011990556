html {
  font-size: 62.5%;
}

body {
  color: var(--aswad-black);
  font-family: 'Telegraf', sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-query('x-large') {
    font-size: 1.5rem;
  };

  @include media-query('medium') {
    font-size: 1.4rem;
  };
}

.text-2xl {
  --font-size-min: 45px;
  --font-size-max: 85px;
  --scaler: 5vw;
  font-size: clamp( var(--font-size-min), var(--scaler), var(--font-size-max) );
  font-weight: 200;
  letter-spacing: -.005em;
  line-height: 1;

  @include media-query('medium') {
    line-height: 1.067;
  }
}

.text-xl {
  --font-size-min: 35px;
  --font-size-max: 60px;
  --scaler: 5vw;
  font-size: clamp( var(--font-size-min), var(--scaler), var(--font-size-max) );
  font-weight: 200;
  letter-spacing: -.005em;
  line-height: 1.067;
  list-style: none;
}

.text-lg {
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.125;

  @include media-query('large') {
    font-size: 3rem;
  }

  @include media-query('medium') {
    font-size: 2.7rem;
  }

  @include media-query('small') {
    font-size: 2.4rem;
  }
}

.text-md {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.2;

  @include media-query('x-large') {
    font-size: 1.8rem;
  }

  @include media-query('medium') {
    font-size: 1.7rem;
  }
}

.text-sm {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;

  @include media-query('x-large') {
    font-size: 1.5rem;
  }

  @include media-query('medium') {
    font-size: 1.4rem;
  }
}

.text-xs {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;

  @include media-query('medium') {
    font-size: 1.2rem;
  }

  @include media-query('small') {
    font-size: 1.1rem;
  }
}

h1,
h2,
h3,
h4,
p {
  display: block;
  margin: 0;
}

.list-unordered {
  padding-left: 1.6rem;
  
  li {
    padding-left: 1rem;
    margin-bottom: .5em;
  }
}

.list-bulletless {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: .5em;
  }
}

.line-height-lg {
  line-height: 1.4 !important;
}

p + p {
  margin-top: 1em;
}