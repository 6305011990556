// Breakpoints

@mixin media-query($breakpoint) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Transition

@mixin transition {
  transition: all .25s;
  transition-timing-function: cubic-bezier(0.45,0.05,0.55,0.95);
}