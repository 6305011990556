@font-face {
  font-family: 'Telegraf';
  src: local('Telegraf'), local('Telegraf'),
    url('./assets/fonts/Telegraf-UltraLight.woff2') format('woff2'),
    url('./assets/fonts/Telegraf-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: local('Telegraf'), local('Telegraf'),
    url('./assets/fonts/Telegraf-Light.woff2') format('woff2'),
    url('./assets/fonts/Telegraf-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: local('Telegraf'), local('Telegraf'),
    url('./assets/fonts/Telegraf-Regular.woff2') format('woff2'),
    url('./assets/fonts/Telegraf-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}