// Border flush left

.b-flush-left {
  @include media-query('small') {
    margin-left: -2rem;
    padding-left: 2rem;
  }
}

// Border-radius

.rounded {
  border-radius: 10px;
  @include media-query('medium') {
    border-radius: 5px;
  }
}

// Gap

.col-gap {
  column-gap: 2.5rem;
  @include media-query('x-large') {
    column-gap: 2rem;
  }
  @include media-query('medium') {
    column-gap: 1.5rem;
  }
}

.outer-gap {
  padding-right: 4rem;
  padding-left: 4rem;
  @include media-query('large') {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  @include media-query('medium') {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

// Spacing

.mt-xl {
  margin-top: 16rem;
  @include media-query('large') {
    margin-top: 14rem;
  }
  @include media-query('medium') {
    margin-top: 12rem;
  }
}

.mt-lg {
  margin-top: 10rem;
  @include media-query('large') {
    margin-top: 8rem;
  }
  @include media-query('medium') {
    margin-top: 6rem;
  }
}

.mt-md {
  margin-top: 8rem;
  @include media-query('large') {
    margin-top: 6rem;
  }
  @include media-query('medium') {
    margin-top: 4rem;
  }
}

.mt-sm {
  margin-top: 4rem;
  @include media-query('large') {
    margin-top: 3rem;
  }
  @include media-query('medium') {
    margin-top: 2rem;
  }
}

.pb-xl {
  padding-bottom: 16rem;
  @include media-query('large') {
    padding-bottom: 14rem;
  }
  @include media-query('medium') {
    padding-bottom: 12rem;
  }
}

.pb-lg {
  padding-bottom: 10rem;
  @include media-query('large') {
    padding-bottom: 8rem;
  }
  @include media-query('medium') {
    padding-bottom: 6rem;
  }
}

.pb-md {
  padding-bottom: 8rem;
  @include media-query('large') {
    padding-bottom: 6rem;
  }
  @include media-query('medium') {
    padding-bottom: 4rem;
  }
}

.pb-sm {
  padding-bottom: 4rem;
  @include media-query('large') {
    padding-bottom: 3rem;
  }
  @include media-query('medium') {
    padding-bottom: 2rem;
  }
}

.pt-sm {
  padding-top: 4rem;
  @include media-query('large') {
    padding-top: 3rem;
  }
  @include media-query('medium') {
    padding-top: 2rem;
  }
}

.pb-xs {
  padding-bottom: 2rem;
  @include media-query('medium') {
    padding-bottom: 1rem;
  }
}

.pt-2xs {
  padding-top: 1rem;
  @include media-query('medium') {
    padding-top: .5rem;
  }
}

// Hide breakpoint and above

.hide-lg {
  @include media-query('large') {
    display: none;
  }
}

.hide-md {
  @include media-query('medium') {
    display: none;
  }
}

.hide-sm {
  @include media-query('small') {
    display: none;
  }
}

// Hide breakpoint and above

.hide-md-up {
  @media only screen and (min-width: 992px) {
    display: none !important;
  }
}

.hide-sm-down {
  @media only screen and (min-width: 576px) {
    display: none !important;
  }
}

// Image
.img-fluid {
  width: 100%;
  height: auto;
}

// Position
.relative {
  position: relative;
}

// Screen-reader only
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Debug — Uncomment to use

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }r