.box {
  background: #FFF;
  @extend .outer-gap;
  @extend .pb-xl;
  @extend .text-sm;
}

.box-end {
  background: #FFF;
  @extend .outer-gap;
  @extend .pb-lg;
  @extend .text-sm;
}

.box-pb-sm {
  background: #FFF;
  @extend .outer-gap;
  @extend .pb-sm;
  @extend .text-sm;
}