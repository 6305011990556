html,
* {
  cursor: none;
  @include media-query('small') {
    cursor: default;
  }
}

body {
  background: #FFF;
  cursor: none;
  z-index: 10;
}

.cursor{
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FFF;
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 9;
  transition: transform 0.2s;
  @include media-query('small') {
    display: none;
  }
}