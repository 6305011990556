:root {
  --aswad-black: #19191E;
  --limestone: #D7D7DB;
  --black: #000;
  --border: 1px solid var(--aswad-black);
  --border-hover: 1px solid var(--black);
  --spacer: .5rem;
}

$breakpoints: (
  'x-large': (max-width: 1680px),
  'large': (max-width: 1200px),
  'medium': (max-width: 992px),
  'small': (max-width: 575px),
) !default;

$gutters: (
  'default': 2.5rem,
  'x-large': 2rem,
  'large': 2rem,
  'medium': 1.6rem,
  'small': 1.6rem,
) !default;