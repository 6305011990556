.site-footer {
  border-top: var(--border);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content min-content; 
  grid-template-areas: 
    "brand brand . . . . nav nav social social link-top link-top"
    ". . . . . . legal legal legal legal legal legal"; 
  @extend .b-flush-left;
  @extend .col-gap;
  @extend .pt-sm;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "nav nav social social brand brand"
      "legal legal legal legal legal legal"; 
  }

  .brand {
    grid-area: brand;

    @include media-query('small') {
      display: flex;
      justify-content: flex-end; 
    }
  }

  .nav {
    grid-area: nav;
  }

  .social {
    grid-area: social;

    li {
      a {
        display: flex;

        span {
          display: block;
        }

        i {
          display: none;
          margin-left: .5rem;
        }
      }
    }
  }

  .link-top {
    display: flex;
    justify-content: end;
    grid-area: link-top;
    @include media-query('small') {
      display: none;
    }
  }

  .legal {
    border-top: var(--border);
    display: flex;
    justify-content: space-between;
    grid-area: legal;
    @extend .mt-md;
    @extend .pt-sm;

    br {
      @extend .hide-md-up;
    }
  }

   a {
    @include transition;
    
    &:hover {
      color: var(--black);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1rem;

      a {
        &:hover {
          i {
            -moz-animation: fadein .5s; /* Firefox */
            -webkit-animation: fadein .5s; /* Safari and Chrome */
            -o-animation: fadein .5s; /* Opera */
            animation: fadein .5s;
            display: flex;
          }
        }
      }
    }
  }
}