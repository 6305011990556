.section-contact {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-areas: 
    ". . . . . . section-content section-content section-content section-content section-content section-content"; 
  @extend .col-gap;

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas: 
      "section-content section-content section-content section-content section-content section-content"; 
  }

  .section-content {
    grid-area: section-content;

    .list-contact {
      display: grid; 
      align-items: start;
      grid-template-columns: 1fr 1fr; 
      grid-template-areas: 
        "section-link section-link"; 
      @extend .col-gap;
    }
  }
}