.wordmark {
  background: url('./assets/images/wordmark.svg') no-repeat;
  background-size: cover;
  display: block;
  height: 11px;
  width: 176px;
  @include media-query('medium') {
    height: 10px;
    width: 160px;
  }
  @include media-query('small') {
    height: 9px;
    width: 144px;
  }
}