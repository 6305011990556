#flash-banner {
  position: relative;
  align-items: center;
  background: var(--aswad-black);
  color: var(--limestone);
  display: flex;
  height: 40px;
  opacity: 1;
  //Var to change for responsive
  overflow: hidden;
  width: 100%;
  z-index: 1;
  @extend .outer-gap;
  @include transition;

  .message {
    flex: 1 1 auto;
    @extend .text-xs;

    a {
      border-bottom: 1px solid transparent;
      color: var(--limestone);
      @include transition;
      &:hover {
        color: #FFF;
      }
      span {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  
  button.btn-close {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--limestone);
    cursor: pointer;
    display: inline-flex;
    height: 20px;
    justify-content: center;
    padding: 0;
    text-align: center;
    text-decoration: none;
    user-select: none;
    width: 20px;
    @include transition;
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  &.hidden {
    transition: all 0.25s ease;
    height: 0px;
  }
}