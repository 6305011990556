.section-link {
  border-top: var(--border);
  padding-top: 1rem;
  @include transition;

  &:hover {
    border-top: var(--border-hover);
    color: var(--black);

    i { 
      margin-right: 2rem;
    }
  }

  i {
    margin-right: 1rem;
    @include transition;
  }
}