.section-company {
  border-top: var(--border);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content 1fr max-content; 
  grid-template-areas: 
    "section-image section-image section-image section-image . section-no section-heading section-heading section-heading section-heading section-heading section-heading"
    "section-image section-image section-image section-image . . section-text section-text section-text section-text . ."
    "section-image section-image section-image section-image . . section-link section-link section-link . . .";
  @extend .b-flush-left;
  @extend .col-gap;
  @extend .pt-sm;

  @include media-query('medium') {
    grid-template-rows: min-content min-content min-content min-content; 
    grid-template-areas: 
      "section-image section-image section-image section-image section-no section-no section-heading section-heading section-heading section-heading section-heading section-heading"
      "section-image section-image section-image section-image . . section-text section-text section-text section-text section-text ."
      "section-image section-image section-image section-image . . section-text section-text section-text section-text section-text ."
      "section-image section-image section-image section-image . . section-link section-link section-link section-link section-link ."; 
  }

  @include media-query('small') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas: 
    "section-heading section-heading section-heading section-heading section-heading section-heading"
    "section-text section-text section-text section-text section-text ."
    "section-image section-image section-image section-image section-image section-image"
    "section-link section-link section-link section-link section-link .";  
  }

  .section-image {
    grid-area: section-image;
    line-height: 0;

    img {
      @extend .img-fluid;
    }
  }

  .section-no { 
    grid-area: section-no; 
    @extend .hide-sm;
  }

  .section-heading {
    grid-area: section-heading;
    @extend .pb-sm;
  }

  .section-text {
    grid-area: section-text;
    @extend .pb-lg;
  }

  .section-link {
    align-content: end;
    grid-area: section-link;
    @extend .mt-sm;
  }
}