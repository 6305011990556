.section-partners {
  border-top: var(--border);
  display: grid; 
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  grid-template-rows: min-content min-content; 
  grid-template-areas: 
    "section-no . section-heading section-heading"
    ". section-content section-content section-content"; 
  width: 100%;
  @extend .b-flush-left;
  @extend .col-gap;
  @extend .pt-sm;

  @include media-query('small') {
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-template-areas: 
      "section-heading"
      "section-content"; 
  }

  .section-no { 
    align-items: start;
    grid-area: section-no; 
    @extend .hide-sm;
  }

  .section-heading {
    align-items: start;
    grid-area: section-heading;
    @extend .pb-lg;
  }

  .section-content {
    align-items: start;
    grid-area: section-content;

    .list-partners {
      display: grid; 
      align-items: start;
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: min-content min-content min-content; 
      grid-template-areas: 
        "list-item list-item list-item"
        "list-item list-item list-item"
        "list-item list-item list-item"; 
      margin: 0;
      padding: 0;
      @extend .col-gap;

      @include media-query('small') {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: min-content min-content min-content min-content; 
        grid-template-areas: 
          "list-item list-item"
          "list-item list-item"
          "list-item list-item"
          "list-item list-item"; 
      }

      .list-item {
        border-top: var(--border);
        @extend .pt-2xs;
        @extend .pb-xs;
      }
    }
  }
}