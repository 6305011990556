.navbar-container {
  height: 25vh;
}

.navbar {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr; 
  width: 100%;
  @extend .col-gap;
  @extend .outer-gap;
  @extend .pt-sm;

  @include media-query('small') {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);grid-auto-rows: minmax(min-content, max-content);
  }

  .brand {
    grid-area: 1 / 1 / 2 / 4;
    padding-top: 1.5rem;
    
    @include media-query('small') {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 1.2rem;
    }
  }
  
  .nav-item-1 {
    grid-area: 1 / 4 / 2 / 5;

    @include media-query('small') {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  .nav-item-2 {
    grid-area: 1 / 5 / 2 / 6; 

    @include media-query('small') {
      grid-area: 2 / 3 / 3 / 4;
    }
  }

  .nav-item-3 {
    grid-area: 1 / 6 / 2 / 7;

    @include media-query('small') {
      grid-area: 3 / 3 / 4 / 4;
    }
  }

  .nav-link {
    border-top: var(--border);
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    @include transition;

    @include media-query('small') {
      padding: .75rem 0 .5rem 0;
    }

    &:hover {
      border-top: var(--border-hover);
      color: var(--black);
      .ph-arrow-down-right {
        -moz-animation: fadein .5s; /* Firefox */
        -webkit-animation: fadein .5s; /* Safari and Chrome */
        -o-animation: fadein .5s; /* Opera */
        animation: fadein .5s;
        display: flex;
      }
    }
  }

  .nav-text {
    display: block;
  }

  .ph-arrow-down-right {
    display: none;
    @include transition;
  }
}