.btn-scroll-down {
  position: absolute;
  height: 100vh;
  width: 100%;
  pointer-events: none;

  .inner {
    position: relative;
    height: 100vh;

    .container {
      bottom: 4rem;
      left: 4rem;
      position: absolute;

      @include media-query('large') {
        bottom: 3rem;
        left: 3rem;
      }

      @include media-query('medium') {
        bottom: 2rem;
        left: 2rem;
      }

      a {
        pointer-events: auto;
        position: relative;
        z-index: 8;
      }
    }
  }
}