section {
  opacity: 0;
}

body {
  animation: fadeIn 1s;
  -moz-animation: fadeIn 1s; /* Firefox */
  -webkit-animation: fadeIn 1s; /* Safari and Chrome */
  -o-animation: fadeIn 1s; /* Opera */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn { /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn { /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn { /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}